var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{staticClass:"fill-height"},[_c('EnvironmentIndicator'),_c('TheAlerts'),_c('v-content',[_c('v-container',{attrs:{"fill-height":""}},[_c('v-layout',{attrs:{"align-center":"","column":"","justify-center":""}},[_c('v-form',{ref:"setpass",staticStyle:{"margin-bottom":"100px"}},[_c('v-layout',{attrs:{"column":"","align-center":"","justify-center":""}},[_c('v-flex',[_c('h1',[_vm._v("Set Password")])]),_c('v-layout',{staticClass:"forgot_form sheet elevation-1",attrs:{"column":""}},[_c('CRInput',{attrs:{"id":"setPasswordone","type":"password","floatinglabel":"New Password","placeholder":"","rules":[
                  (v) => _vm.isNotEmpty(v) || 'This field is required',
                  (v) => !_vm.shouldValidatePassword || _vm.asyncPasswordResponse.data.valid || _vm.asyncPasswordResponse.data.message
                ]},model:{value:(_vm.passOne),callback:function ($$v) {_vm.passOne=$$v},expression:"passOne"}}),_c('CRInput',{attrs:{"id":"setPasswordtwo","type":"password","floatinglabel":"Confirm Password","placeholder":"","rules":[
                  (v) => _vm.isNotEmpty(v) || 'This field is required',
                  (v) => _vm.passwordsMatch(v) || 'Passwords must match'
                ]},model:{value:(_vm.passTwo),callback:function ($$v) {_vm.passTwo=$$v},expression:"passTwo"}}),_c('v-layout',{attrs:{"row":"","align-center":"","justify-center":""}},[_c('v-btn',{staticClass:"btn-secondaryaction",attrs:{"id":"set-pass-button-cancel"},on:{"click":function($event){return _vm.$router.push({ name: 'login' })}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"btn-primaryaction",attrs:{"id":"set-pass-button-submit","disabled":_vm.loading,"loading":_vm.loading},on:{"click":_vm.setPassword}},[_vm._v(" Submit ")])],1)],1),_c('v-layout',{attrs:{"column":""}},[_c('div',{staticStyle:{"font-size":"0.8em","margin-top":"30px"}},[_vm._v(" By clicking submit you agree to our terms and conditions and privacy policy. ")]),_c('img',{staticClass:"logo-set",attrs:{"src":require("@/assets/images/logo-coachrail.svg")}})])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }