<template>
  <v-app class="fill-height">
    <EnvironmentIndicator />
    <TheAlerts />
    <v-content>
      <v-container fill-height>
        <v-layout align-center column justify-center>
          <v-form ref="setpass" style="margin-bottom: 100px">
            <v-layout column align-center justify-center>
              <v-flex><h1>Set Password</h1></v-flex>

              <v-layout column class="forgot_form sheet elevation-1">
                <CRInput
                  id="setPasswordone"
                  v-model="passOne"
                  type="password"
                  floatinglabel="New Password"
                  placeholder=""
                  :rules="[
                    (v) => isNotEmpty(v) || 'This field is required',
                    (v) => !shouldValidatePassword || asyncPasswordResponse.data.valid || asyncPasswordResponse.data.message
                  ]"
                />

                <CRInput
                  id="setPasswordtwo"
                  v-model="passTwo"
                  type="password"
                  floatinglabel="Confirm Password"
                  placeholder=""
                  :rules="[
                    (v) => isNotEmpty(v) || 'This field is required',
                    (v) => passwordsMatch(v) || 'Passwords must match'
                  ]"
                />

                <v-layout row align-center justify-center>
                  <v-btn
                    id="set-pass-button-cancel"
                    class="btn-secondaryaction"
                    @click="$router.push({ name: 'login' })"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    id="set-pass-button-submit"
                    :disabled="loading"
                    :loading="loading"
                    class="btn-primaryaction"
                    @click="setPassword"
                  >
                    Submit
                  </v-btn>
                </v-layout>
              </v-layout>

              <v-layout column>
                <div style="font-size: 0.8em; margin-top: 30px">
                  By clicking submit you agree to our terms and conditions and
                  privacy policy.
                </div>
                <img
                  class="logo-set"
                  src="@/assets/images/logo-coachrail.svg"
                />
              </v-layout>
            </v-layout>
          </v-form>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import { isNotEmpty } from '@/utils/validators'
import { mapActions } from 'vuex'
import TheAlerts from '@/components/TheAlerts.vue'
import authService from '@/services/auth'
import EnvironmentIndicator from '@/components/EnvironmentIndicator.vue'

export default {
  components: {
    EnvironmentIndicator,
    TheAlerts,
  },
  metaInfo() {
    return {
      title: 'Set Password',
    }
  },
  props: {
    token: {
      default: '',
      type: String,
    },
    welcome: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      passOne: '',
      passTwo: '',
      loading: false,
      errorMessage: '',
      asyncPasswordResponse: {
        data: {
          valid: false,
          message: 'Something went wrong'
        }
      },
      shouldValidatePassword: false
    }
  },
  methods: {
    isNotEmpty,
    ...mapActions({ showAlert: 'app/showAlert' }),
    ...mapActions({ setPass: 'auth/setPassword' }),
    async setPassword() {
      this.loading = true

      this.asyncPasswordResponse = await authService.validatePassword(this.passOne)
      this.shouldValidatePassword = true

      if (!this.$refs.setpass.validate()) {
        this.loading = false
        return
      }

      try {
        const user = await this.setPass({
          hash: this.token,
          password: this.passOne,
        }).then((res) => res.data)

        this.showAlert({
          message:
            'Password set successfully! Please log in with your new password!',
        })

        this.$router.push({ name: 'login' })

      } catch (e) {
        this.showAlert({
          message: 'Something went wrong',
          type: 'error',
        })
      }
      this.loading = false
    },

    passwordsMatch(password) {
    return password === this.passOne
    }
  },
}
</script>

<style lang="scss" scoped>
#app {
  background-image: url('~@/assets/images/icon-bg.svg');
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
}

.login-container {
  width: 30%;
  min-width: 300px;
}

.login__logo {
  width: 60%;
  margin: 0 auto 25px auto;
}

.login-prompt {
  margin-top: 30px;
  font-size: 16px;
  text-align: center;

  .phone {
    font-weight: bold;
    text-decoration: none;
  }
}

.forgot_form {
  width: 400px;

  .text-box {
    width: 300px;
    text-align: center;
    margin-bottom: 15px;
  }
}

.logo-set {
  width: 120px;
  height: 20px;
  align-self: center;
  margin-top: 20px;
}
</style>
